<template>
  <b-container class="pt-2">
    <b-button class="mb-5" size="sm" variant="outline-primary" v-bind:to="`/users/${$route.params.id}/delivery-address`">
      <b-icon-arrow-left/>
      К списку адресов
    </b-button>
    <item-info :item-get-function="getDeliveryAddress" :fields="fields">
    </item-info>
  </b-container>
</template>

<script>
import deliveryAddressService from "@/modules/delivery-address-service";

export default {
  name: "delivery-address",
  data() {
    return {
      fields: [
        {key: "id", label: "ID"},
        {key: "street", label: "Улица"},
        {key: "building", label: "Дом"},
        {key: "flat", label: "Квартира"},
        {key: "entrance", label: "Подъезд"},
        {key: "floor", label: "Этаж"},
        {key: "doorCode", label: "Код двери"},
        {key: "addressName", label: "Название адреса"},
        {key: "comment", label: "Комментарий"}
      ]
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    async getDeliveryAddress() {
      return (await deliveryAddressService.getFullInfoAddressById(this.$route.params.addressId)).data;
    }
  }
}
</script>