<template>
  <div class="text-center d-flex justify-content-center">
    <b-spinner label="Загрузка"/>
    <strong class="pt-1 pl-2">Загрузка...</strong>
  </div>
</template>

<script>
export default {
  name: "loading-spinner"
}
</script>