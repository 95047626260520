import {USER_MANAGEMENT_AXIOS} from '@/modules/backend-config';

export default {
    getServices() {
        return USER_MANAGEMENT_AXIOS.get('/services');
    },
    getService(id) {
        return USER_MANAGEMENT_AXIOS.get(`/services/${id}`);
    },
    deleteService(id) {
        return USER_MANAGEMENT_AXIOS.delete(`/services/${id}`);
    },
    createService(service) {
        return USER_MANAGEMENT_AXIOS.post('/services',
            JSON.stringify(service),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    },
    updateService(service) {
        return USER_MANAGEMENT_AXIOS.put('/services',
            JSON.stringify(service),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    },
    getAllRoles() {
        return USER_MANAGEMENT_AXIOS.get("/roles");
    }
}
