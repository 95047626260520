import { render, staticRenderFns } from "./UserProfileRead.vue?vue&type=template&id=7edca69a&scoped=true"
import script from "./UserProfileRead.vue?vue&type=script&lang=js"
export * from "./UserProfileRead.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7edca69a",
  null
  
)

export default component.exports