<template>
  <b-card v-if="!loading" class="border-0">
    <template #header>
      <b-button size="sm" variant="outline-primary" v-bind:to="'/users'">
        <b-icon-arrow-left/>
        К списку пользователей
      </b-button>
      <h4 v-if="user != null" class="mt-4 mb-3">
        {{ username }}
      </h4>
      <user-nav :nav-items=data.navItems></user-nav>
    </template>
    <b-card-body v-if="user != null" class="p-0">
      <router-view/>
    </b-card-body>
    <h4 v-else class="text-center mt-2">
      Не удалось загрузить данные пользователя
    </h4>
  </b-card>
  <loading-spinner class="mt-5" v-else/>
</template>

<script>
import UserNavItem from "@/components/UserNavItem";
import userService from "@/modules/users-service";
import {mapGetters, mapMutations} from "vuex";
import UserNav from "@/components/UserNav.vue";

export default {
  name: "user",
  components: {
    UserNav,
    'user-nav-item': UserNavItem
  },
  data() {
    return {
      data: {
        navItems: [
          {
            name: 'Профиль',
            path: `/users/${this.$route.params.id}/profile`
          },
          {
            name: 'Заказы',
            path: `/users/${this.$route.params.id}/orders`
          },
          {
            name: 'Избранные',
            path: `/users/${this.$route.params.id}/favorites`
          },
          {
            name: 'Список ожидания',
            path: `/users/${this.$route.params.id}/waiting-list`
          },
          {
            name: 'Адреса доставки',
            path: `/users/${this.$route.params.id}/delivery-address`
          },
          {
            name: 'Корзина',
            path: `/users/${this.$route.params.id}/shop-cart`
          }
        ]
      },
      loading: false
    };
  },
  created() {
    this.loading = true;
    userService.getUser(this.$route.params.id)
        .then(response => {
          this.setUser(response.data);
        })
        .catch(error => {
          this.$bvToast.toast('Не удалось загрузить данные пользователя' + (error.response == null ? '' : ` (код ${error.response.status})`), {
            title: 'Ошибка',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          });
        })
        .finally(() => {
          this.loading = false;
        });
    document.title = this.$route.meta.title;
  },
  computed: {
    username() {
      if ((this.user.lastName == null || this.user.lastName.length === 0)
          && (this.user.firstName == null || this.user.firstName.length === 0)) {
        return `Пользователь #${this.$route.params.id}`
      } else {
        return `${this.user.lastName ?? ""} ${this.user.firstName ?? ""} ${this.user.patronymic ?? ""} ${this.user.phone ?? ""}`;
      }
    },
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["setUser"])
  }
}
</script>

<style scoped>
/deep/ .nav-item a {
  color: #495057;
}
</style>
