import store from "@/store";
import router from "@/router";

export default {
    addUnauthorizedInterceptor(axios) {
        axios.interceptors.response.use(response => response, error => {
            if (error.response?.status === 401) {
                return store.dispatch("refreshToken")
                    .then(_ => {
                        error.config.baseURL = undefined;
                        return axios.request(error.config);
                    })
                    .catch(err => {
                        return router.push('/login')
                            .then(() => {
                                return Promise.reject(err);
                            });
                    });
            }
            return Promise.reject(error);
        });
    }
}