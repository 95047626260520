import {FAVORITE_AXIOS} from "@/modules/backend-config";

export default {
    getFavoriteList(userId, pageSize, pageNumber, sortBy, sortDirection) {
        return FAVORITE_AXIOS.get('/sortedAll', {
            params: {
                userId,
                pageSize,
                pageNumber,
                sortBy,
                sortDirection
            }
        });
    }
}