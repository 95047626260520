import {USER_MANAGEMENT_AXIOS} from '@/modules/backend-config';

export default {
    getUsers(pageNumber, pageSize, sortBy, sortDirection, filter) {
        return USER_MANAGEMENT_AXIOS.get('/users',
            {
                params: {
                    pageNumber,
                    pageSize,
                    sortBy,
                    sortDirection,
                    phone: filter.phone || null,
                    login: filter.login || null,
                    email: filter.email || null,
                    user_type: filter.userType || null
                }
            });
    },
    getUser(id) {
      return USER_MANAGEMENT_AXIOS.get(`/users/${id}`);
    },
    deleteUser(id) {
        return USER_MANAGEMENT_AXIOS.delete(`/users/${id}`);
    },
    createUser(user) {
        return USER_MANAGEMENT_AXIOS.post('/users',
            JSON.stringify(user),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    },
    updateUser(user) {
        return USER_MANAGEMENT_AXIOS.put('/users',
            JSON.stringify(user),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    }
}
