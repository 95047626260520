<template>
  <b-container class="pt-2">
    <b-form class="mb-3" @submit.stop.prevent="onSubmit">
      <b-form-group label="Логин">
        <b-form-input v-model="$v.userData.login.$model"
                      :readonly="!isAdmin"
                      @blur="$v.userData.login.$touch"
                      aria-describedby="login-invalid"
                      :state="$v.userData.login.$dirty ? !$v.userData.login.$error : null">
        </b-form-input>
        <b-form-invalid-feedback id="login-invalid">
          <span v-if="!$v.userData.login.required">Требуется заполнить, если номер телефона или email пуст</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Email">
        <b-form-input v-model="$v.userData.email.$model"
                      :readonly="!isAdmin"
                      @blur="$v.userData.email.$touch"
                      aria-describedby="email-invalid"
                      :state="$v.userData.email.$dirty ? !$v.userData.email.$error : null">
        </b-form-input>
        <b-form-invalid-feedback id="email-invalid">
          <span v-if="!$v.userData.email.email">Некорректно введён адрес электронной почты</span>
          <span v-if="!$v.userData.email.required">Требуется заполнить, если номер телефона пуст</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Номер телефона">
        <b-form-input v-mask="'+9-999-999-99-99'" v-model="$v.userData.phone.$model"
                      :readonly="!isAdmin"
                      @blur="$v.userData.email.$touch"
                      aria-describedby="phone-invalid"
                      :state="$v.userData.phone.$dirty ? !$v.userData.phone.$error : null">
        </b-form-input>
        <b-form-invalid-feedback id="phone-invalid">
          Некорректно введён номер телефона
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Фамилия">
        <b-form-input v-model="$v.userData.lastName.$model" :readonly="!isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Имя">
        <b-form-input v-model="$v.userData.firstName.$model" :readonly="!isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Отчество">
        <b-form-input v-model="$v.userData.patronymic.$model" :readonly="!isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Дата рождения">
        <b-form-datepicker placeholder="Выберите дату" v-model="$v.userData.dateOfBirth.$model" :readonly="!isAdmin">
        </b-form-datepicker>
      </b-form-group>
      <b-form-group v-if="!creating" label="Email подвтерждён">
        <b-form-input readonly :value="userData.emailConfirm ? 'Да' : 'Нет'"></b-form-input>
      </b-form-group>
      <b-form-group v-if="!creating" label="Сгенерированный пароль сменён">
        <b-form-input readonly :value="userData.passwordActivated ? 'Да' : 'Нет'"></b-form-input>
      </b-form-group>
      <b-form-group class="my-5">
        <b-form-group :label="'Пароль' + (!creating ? ' (оставьте пустым, чтобы не менять пароль)' : '')">
          <b-form-input v-model="$v.userData.password.$model"
                        :readonly="!isAdmin"
                        @blur="$v.userData.email.$touch"
                        type="password"
                        :state="$v.userData.password.$dirty ? !$v.userData.password.$error : null">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Повторите пароль">
          <b-form-input v-model="$v.verifyPassword.$model"
                        :readonly="!isAdmin"
                        @blur="$v.userData.email.$touch"
                        type="password"
                        aria-describedby="password-validation"
                        :state="$v.verifyPassword.$dirty ? !$v.verifyPassword.$error : null">
          </b-form-input>
          <b-form-invalid-feedback id="password-validation">
            Пароли не совпадают
          </b-form-invalid-feedback>
        </b-form-group>
        <div v-if="isAdmin">
          <b-button class="mt-1 mr-1" @click="generatePassword">Сгенерировать пароль</b-button>
          <b-button class="mt-1" @click="copyPasswordToClipboard">Копировать пароль</b-button>
        </div>
      </b-form-group>
      <b-form-group label="Роли">
        <b-card v-if="allServices != null && allServices.length > 0">
          <b-table responsive :items="allServices.slice(0, allServices.length - 1)" :fields="fields">
            <template v-slot:cell(service)="row">
              {{ `${row.item.name} (${row.item.description})` }}
            </template>
            <template v-for="field in fields.slice(1)" v-slot:[`cell(${field})`]="row">
              <b-form-checkbox class="ml-3" :disabled="!isAdmin"
                               @change="changeRoles($event, row.item.name, field)"
                               :checked="userData.services.some(service => service.serviceName === row.item.name && service.roles.includes(field))"/>
            </template>
          </b-table>
          <b-card class="mt-4" border-variant="danger">
            <b-row class="justify-content-md-center">
              <b-col>
                <div>
                  {{
                    `${allServices[allServices.length - 1].name} (${allServices[allServices.length - 1].description})`
                  }}
                </div>
              </b-col>
              <b-col :class="role === 'USER' ? 'user-role': 'admin-role'">
                <b-row>
                  <b-col v-for="role in allServices[allServices.length - 1].roles" :key="role">
                    <b-form-checkbox @change="changeRoles($event, allServices[allServices.length - 1].name, role)"
                                     :checked="userData.services.some(service => service.serviceName === allServices[allServices.length - 1].name && service.roles.includes(role))"
                                     :disabled="!isAdmin"/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-card>
      </b-form-group>
      <b-form-group label="Полезная нагрузка">
        <b-card>
          <b-button class="mb-2" type="button" variant="primary" @click="userData.claims.push({name: '', value: ''})">Добавить</b-button>
          <div v-if="userData.claims.length === 0">
            Полезная нагрузка отсутствует
          </div>
          <div v-for="(claim, index) in userData.claims" :key="index">
            <b-input-group class="mb-2">
              <b-input-group-prepend v-show="claim.name === null || claim.name.length === 0">
                <b-dropdown text="Выбрать" variant="info">
                  <b-dropdown-item @click="claim.name = 'code'">Код провайдера</b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input v-model="claim.name"
                            :readonly="!isAdmin"
                            :state="$v.userData.claims.$each[index].name.$dirty ? !$v.userData.claims.$each[index].name.$error : null"
                            placeholder="Наименование">
              </b-form-input>
              <b-form-input v-model="claim.value"
                            :readonly="!isAdmin"
                            :state="$v.userData.claims.$each[index].value.$dirty ? !$v.userData.claims.$each[index].value.$error : null"
                            placeholder="Значение">
              </b-form-input>
              <b-input-group-append>
                <b-button type="button" variant="primary" @click="userData.claims.splice(index, 1)">
                  <b-icon-trash/>
                </b-button>
              </b-input-group-append>
              <b-form-invalid-feedback v-if="!$v.userData.claims.$each[index].name.required">
                Наименование не должно быть пустым
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.userData.claims.$each[index].value.required">
                Значение не должно быть пустым
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.userData.claims.$each[index].name.unique">
                Дублирующееся наименование
              </b-form-invalid-feedback>
            </b-input-group>
          </div>
        </b-card>
      </b-form-group>
      <div>
        <b-button v-if="isAdmin" variant="primary" class="w-100 mt-4" type="submit" :disabled="submitting">
          Сохранить
        </b-button>
      </div>
      <b-button class="w-100 mt-4" @click="$router.back()">
        Назад
      </b-button>
    </b-form>
  </b-container>
</template>
<script>
import userApi from '@/modules/users-service';
import serviceApi from '@/modules/roles-service';
import {mapGetters} from "vuex";
import {email, required, requiredUnless} from "vuelidate/lib/validators";
import {generate} from "generate-password";
import AwesomeMask from 'awesome-mask';

export default {
  directives: {
    'mask': AwesomeMask
  },
  async mounted() {
    if (this.$route.params.id != null) {
      await this.fetchUser(this.$route.params.id);
    } else {
      this.creating = true;
    }
    this.getAllRoles()
    this.setUserRoles();
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      creating: false,
      submitting: false,
      currentService: null,
      allServices: [],
      allRoles: [],
      fields: [
        {
          key: 'service', label: 'Сервис'
        }
      ],
      verifyPassword: null,
      userData: {
        login: null,
        phone: null,
        firstName: null,
        lastName: null,
        patronymic: null,
        dateOfBirth: null,
        email: null,
        password: null,
        emailConfirm: null,
        passwordActivated: null,
        services: [],
        claims: []
      },
    };
  },
  validations() {
    return {
      userData: {
        login: {
          required: requiredUnless(() => this.userData.phone || this.userData.email)
        },
        email: {
          email,
          required: requiredUnless(() => this.userData.phone)
        },
        password: {
          required: (value) => this.creating ? value !== null && value.length > 0 : true
        },
        phone: {
          pattern: (value) => value == null || value.length === 0 || new RegExp(/^\+\d-\d{3}-\d{3}-\d{2}-\d{2}$/).test(value)
        },
        firstName: {},
        lastName: {},
        patronymic: {},
        dateOfBirth: {},
        claims: {
          $each: {
            name: {
              required,
              unique: (value) => {
                return this.userData.claims.filter(item => item.name === value).length === 1
              }
            },
            value: {
              required
            }
          }
        },
        services: {},
      },
      currentService: {},
      allServices: {},
      allRoles: {},
      verifyPassword: {
        required: (value) => this.creating ? value !== null && value.length > 0 : true,
        sameAsPassword: (value) => value == null || value.length === 0 || value === this.userData.password
      }
    }
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    changeRoles(checked, service, role) {
      const foundService = this.userData.services.find(userService => userService.serviceName === service);
      if (foundService == null) {
        return;
      }
      if (foundService.roles == null) {
        foundService.roles = [];
      }

      if (checked) {
        foundService.roles.push(role);
      } else {
        foundService.roles = foundService.roles.filter(existingRole => existingRole !== role);
      }
    },
    onSubmit() {
      this.$v.userData.$touch();
      if (this.submitting || this.$v.userData.$anyError || this.$v.verifyPassword.$anyError) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return;
      }
      this.submitting = true;
      this.handleResponse(this.creating ?
          userApi.createUser(this.userData) :
          userApi.updateUser(this.userData));
    },
    handleResponse(axiosCall) {
      axiosCall
          .then(() => {
            this.$router.push('/users');
          })
          .catch(e => {
            if (e.isAxiosError) {
              this.$bvToast.toast(e.response?.status === 403 ?
                  'Недостаточно прав на создание' :
                  e.response?.status === 409 ?
                      e.response.data.message :
                      'Возникла неизвестная ошибка при сохранении', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    fetchUser(id) {
      return userApi.getUser(id)
          .then(resp => {
            this.userData = resp.data;
          });
    },
    setUserRoles() {
      serviceApi.getServices()
          .then(response => {
            const receivedServices = response.data;
            const rootService = receivedServices.find(service => service.name === 'ROOT');
            const sortedServices = receivedServices.filter(service => service !== rootService);
            sortedServices.push(rootService);
            this.allServices = sortedServices;
            this.userData.services = sortedServices
                .map(service => {
                  const foundService = this.userData.services
                      .find(userService => service.id === userService.id);
                  return {
                    serviceName: service.name,
                    roles: foundService == null || foundService.roles == null ? [] : foundService.roles
                  }
                });
          });
    },
    getAllRoles() {
      serviceApi.getAllRoles()
          .then(response => {
            this.fields.push(...response.data);
          });
    },
    generatePassword() {
      this.userData.password = generate({
        length: 12,
        numbers: true
      });
      this.verifyPassword = this.userData.password;
      this.$bvToast.toast('Пароль успешно сгенерирован', {
        variant: 'success',
        solid: true,
        noCloseButton: true
      });
    },
    copyPasswordToClipboard() {
      this.$clipboard(this.userData.password);
      this.$bvToast.toast('Пароль успешно скопирован', {
        variant: 'success',
        solid: true,
        noCloseButton: true
      });
    }
  }
}
</script>
<style scoped>
.user-role {
  margin-left: 5%;
}

.admin-role {
  margin-left: 0.5%;
}
</style>
