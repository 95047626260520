import Vuex from 'vuex'
import Vue from 'vue';
import authModule from '@/store/auth';
import userModule from '@/store/user';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: authModule,
        user: userModule
    }
});