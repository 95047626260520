<template>
  <loading-spinner v-if="loading" class="mt-5"/>
  <div v-else-if="item != null">
    <div v-for="field in fields" :key="getFieldKey(field)">
      <b-form-group :label="getFieldLabel(field)">
        <slot v-bind="item" :name="getFieldKey(field)">
          <b-form-input :readonly="isFieldReadonly(field)"
                        v-model="typeof field === 'string' || field instanceof String ? item[field] : item[field.key]"/>
        </slot>
      </b-form-group>
    </div>
  </div>
  <h4 v-else class="text-center mt-2">
    {{ errorMessage }}
  </h4>
</template>

<script>
export default {
  name: "item-info",
  props: {
    itemGetFunction: {
      type: Function,
      required: true
    },
    fields: {
      type: Array,
      required: false,
      default: []
    }
  },
  data() {
    return {
      loading: false,
      item: null,
      errorMessage: null
    }
  },
  async created() {
    this.loading = true;
    this.errorMessage = null;

    try {
      this.item = await this.itemGetFunction();
      if (this.fields == null || this.fields.length === 0) {
        this.fields = Object.keys(this.item);
      }
    } catch (error) {
      if (error.response != null) {
        if (error.response.status === 403) {
          this.errorMessage = "Недостаточно прав для просмотра страницы";
        } else if (error.response.status === 429) {
          this.errorMessage = "Слишком много запросов";
        } else if (error.response.status === 401) {
          this.errorMessage = "Сессия истекла. Пожалуйста, перезайдите в аккаунт";
        } else if (error.response.status === 404) {
          this.errorMessage = "Страница не найдена";
        } else {
          this.errorMessage = "Не удалось получить данные";
        }
      } else {
        this.errorMessage = "Не удалось получить данные";
      }
      this.$bvToast.toast(this.errorMessage + (error.response == null ? '' : ` (код ${error.response.status})`), {
        title: "Ошибка",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getFieldKey(field) {
      if (typeof field === "string" || field instanceof String) {
        return field;
      } else {
        return field.key;
      }
    },
    getFieldLabel(field) {
      if (typeof field === "string" || field instanceof String) {
        return field;
      } else {
        return field.label;
      }
    },
    isFieldReadonly(field) {
      if (typeof field === "string" || field instanceof String) {
        return false;
      } else {
        return field.readonly ?? true;
      }
    }
  }
}
</script>