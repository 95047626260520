<template>
  <b-nav-item :to="path"
              :active="$route.path.startsWith(path)">{{ name }}</b-nav-item>
</template>

<script>
export default {
  name: "user-nav-item",
  props: {
    path: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    }
  }
}
</script>
