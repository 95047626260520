import axios from 'axios';
import axiosUtils from "@/utils/axios-utils";

const AUTH_AXIOS = axios.create({
    baseURL: '/api/ident',
    timeout: 300000
});

const USER_MANAGEMENT_AXIOS = axios.create({
    baseURL: '/api/user-management',
    timeout: 300000
});

axiosUtils.addUnauthorizedInterceptor(USER_MANAGEMENT_AXIOS);

const ORDER_AXIOS = axios.create({
    baseURL: '/api/shop-order',
    timeout: 3000
});

axiosUtils.addUnauthorizedInterceptor(ORDER_AXIOS);

const FAVORITE_AXIOS = axios.create({
    baseURL: '/api/favorites',
    timeout: 3000
})

axiosUtils.addUnauthorizedInterceptor(FAVORITE_AXIOS)

const WAITING_LIST_AXIOS = axios.create({
    baseURL: '/api/waiting-list',
    timeout: 3000
})

axiosUtils.addUnauthorizedInterceptor(WAITING_LIST_AXIOS)

const SHOP_CART_AXIOS = axios.create({
    baseURL: '/api/shop-cart',
    timeout: 3000
})

axiosUtils.addUnauthorizedInterceptor(SHOP_CART_AXIOS)

const DELIVERY_ADDRESS_AXIOS = axios.create({
    baseURL: '/api/delivery-address',
    timeout: 3000
})

axiosUtils.addUnauthorizedInterceptor(DELIVERY_ADDRESS_AXIOS)

export {USER_MANAGEMENT_AXIOS, AUTH_AXIOS, ORDER_AXIOS, FAVORITE_AXIOS, DELIVERY_ADDRESS_AXIOS, SHOP_CART_AXIOS, WAITING_LIST_AXIOS};
