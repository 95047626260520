import {ORDER_AXIOS} from "@/modules/backend-config";

export default {
    getOrderList(userId, pageSize, pageNumber, sortBy, sortDirection) {
        return ORDER_AXIOS.get('/sortedOrders', {
            params: {
                userId,
                pageSize,
                pageNumber,
                sortBy,
                sortDirection
            }
        });
    },
    getOrder(id) {
        return ORDER_AXIOS.get(`/${id}`);
    }
}