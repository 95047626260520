<template>
  <b-navbar class="py-0 px-0" toggleable="lg">
    <b-row>
      <b-col>
        <b-nav-toggle target="user-nav-collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="chevron-bar-up"/>
            <b-icon v-else icon="chevron-bar-down"/>
          </template>
        </b-nav-toggle>
      </b-col>
      <b-col align-self="center" class="show-for-xx-screen">
        <b-badge pill variant="primary" style="font-size: 1em">{{ getSelectedItem }}</b-badge>
      </b-col>
    </b-row>
    <b-collapse id="user-nav-collapse" is-nav>
      <b-nav class="mt-2 b-nav" pills>
        <user-nav-item v-for="item in navItems" :key="item.name"
                       :name=item.name :path=item.path>
        </user-nav-item>
      </b-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>

import UserNavItem from "@/components/UserNavItem.vue";

export default {
  name: "user-nav",
  components: {
    'user-nav-item': UserNavItem
  },
  props: {
    navItems: {
      required: true,
      type: Array
    }
  },
  computed: {
    getSelectedItem() {
      for (let item of this.navItems) {
        if (this.$route.path.startsWith(item.path))
          return item.name
      }
      return null
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .b-nav {
    flex-direction: column;
  }
}

@media only screen  and (max-width: 991px) {
  .show-for-xx-screen {
    display: flex;
  }
}

@media only screen  and (min-width: 992px) {
  .show-for-xx-screen {
    display: none;
  }
}
</style>
