<template>
  <b-container class="pt-2">
    <b-table
        hover
        :items="getUsers"
        :fields="fields"
        :current-page="orderList.currentPage"
        ref="device_table"
        :per-page="orderList.perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Заказы отсутствуют</h6>
      </template>
      <template v-slot:table-busy>
        <loading-spinner/>
      </template>
      <template v-slot:cell(orderDate)="row">
        {{ new Date(row.item.orderDate).toLocaleString() }}
      </template>
      <template v-slot:cell(deliveryDate)="row">
        {{ row.item.deliveryDate == null ? 'Самовывоз' : new Date(row.item.deliveryDate).toLocaleDateString() }}
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button variant="outline-primary"
                  title="Просмотр"
                  @click="goToDetailPage(row.item.id)">
          <b-icon-eye-fill/>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="orderList.perPage"
            class="text-light border-secondary bg-secondary">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center d-none d-lg-block mb-2">записей на страницу</span>
      <b-pagination
          v-model="orderList.currentPage"
          class="my-0 ml-auto mb-2"
          :total-rows="orderList.totalCount"
          :per-page="orderList.perPage"
          aria-controls="template-table"
          @input="getUsers">
      </b-pagination>
    </div>
  </b-container>
</template>
<script>
import orderService from "@/modules/orders-service";

export default {
  name: 'user-order-list',
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      fields: [
        {
          key: 'id', label: 'ID', sortable: true
        },
        {
          key: 'orderDate', label: 'Дата заказа', sortable: true
        },
        {
          key: 'deliveryDate', label: 'Дата доставки', sortable: true
        },
        {
          key: 'totalCost', label: 'Сумма заказа (руб.)', sortable: true
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      orderList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      }
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    getUsers(context) {
      return orderService.getOrderList(this.$route.params.id, context.perPage, context.currentPage, context.sortBy,
          context.sortDesc ? "Descending" : "Ascending")
          .then(resp => {
            this.orderList.totalCount = resp.data.totalCount;
            return resp.data.orders;
          })
          .catch(error => {
            this.$bvToast.toast('Не удалось загрузить заказы пользователя' + (error.response == null ? '' : ` (код ${error.response.status})`), {
              title: 'Ошибка',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            });
          });
    },
    goToDetailPage(id) {
      this.$router.push(`/users/${this.$route.params.id}/orders/${id}`);
    }
  }
}
</script>
