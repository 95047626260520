<template>
  <b-container class="pt-2">
    <b-button class="mb-5" size="sm" variant="outline-primary" v-bind:to="`/users/${$route.params.id}/shop-cart`">
      <b-icon-arrow-left/>
      Вернуться в корзину
    </b-button>
    <item-info :item-get-function="getProductFromShopCart" :fields="fields">
      <template #state>
        {{ row.item.state == 'AVAILABLE' ? 'Доступен' : 'Недоступен' }}
      </template>
      <template #state="{state}">
        <b-form-input readonly :value="state === 'AVAILABLE' ? 'Доступен' : 'Недоступен'  "/>
      </template>
    </item-info>
  </b-container>
</template>

<script>
import shopCartService from "@/modules/shop-cart-service";

export default {
  name: "delivery-address",
  data() {
    return {
      fields: [
        {key: "id", label: "ID"},
        {key: "name", label: "Название товара"},
        {key: "category", label: "Категория"},
        {key: "price", label: "Цена"},
        {key: "amount", label: "Количество доступных товаров"},
        {key: "quantity", label: "Количество в корзине"},
        {key: "state", label: "Наличие"}
      ]
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    async getProductFromShopCart() {
      return this.mapToProduct((await shopCartService.getFullInfoProductById(this.$route.params.id, this.$route.params.product_id)).data);
    },
    mapToProduct(item) {
      return {
        id: item?.id,
        name: item?.product?.name,
        category: item?.product.category?.name,
        price: item?.product?.price,
        amount: item?.product?.amount,
        quantity: item?.quantity,
        state: item?.product?.state
      }
    }
  }
}
</script>
