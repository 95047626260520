import {DELIVERY_ADDRESS_AXIOS} from "@/modules/backend-config";

export default {
    getDeliveryAddressList(userId, pageSize, pageNumber, sortBy, sortDirection) {
        return DELIVERY_ADDRESS_AXIOS.get('/sortedAll', {
            params: {
                userId,
                pageSize,
                pageNumber,
                sortBy,
                sortDirection
            }
        });
    },
    getFullInfoAddressById(addressId) {
        return DELIVERY_ADDRESS_AXIOS.get(`/admin/${addressId}`);
    }
}
