<template>
  <b-container class="p-0">
    <b-card no-body class="overflow-hidden">
      <b-row no-gutters>
        <b-col class="mx-auto mx-md-0" style="max-width: 200px;" md="6">
          <b-card-img style="max-width: 200px; max-height: 200px" :src="product.thumbnailPath" class="rounded-0"></b-card-img>
        </b-col>
        <b-col md="6">
          <b-card-body :title="product.name" :sub-title="'#' + product.id">
            <b-card-text>
              <b-card-text>{{ product.quantity }} шт. × {{ product.price }} ₽/ед.</b-card-text>
            </b-card-text>
            <b-badge v-if="product.weighed" pill variant="primary">Весовой товар</b-badge>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import moment from "moment";
import {minValue, requiredIf} from "vuelidate/lib/validators";

export default {
  name: "product-details",
  data() {
    return {
      submitting: false,
      curTime: moment(),
      asd: 'https://catalog.preprod.mobile.povorotnaogorod.ru/data/a369f28f-cd3c-3998-bfa9-0bd5dfaaa555_thumbnail.jpg'
    };
  },
  props: {
    product: {
      id: Number,
      name: String,
      thumbnailPath: String,
      price: Number,
      quantity: Number,
      weight: Number,
      comment: String,
      shipment: Boolean,
      weighed: Boolean,
      shipmentDate: Date,
    }
  },
  validations() {
    return {
      product: {
        weight: {
          required: requiredIf(() => this.product.shipment && this.product.weighed),
          minValue: minValue(0)
        },
        shipmentDate: {
          required: requiredIf(() => this.product.shipment)
        },
        comment: {
          required: requiredIf(() => this.product.shipment === false)
        }
      }
    }
  },
  created() {
    document.title = this.$route.meta.title;
  },
}
</script>
<style>
textarea {
  background-color: white !important;
}
</style>
