<template>
  <b-container class="pt-2">
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Название сервиса">
        <b-form-input v-model="$v.serviceData.name.$model" required="required"
                      :readonly="!isAdmin"
                      :state="$v.serviceData.name.$dirty ? !$v.serviceData.name.$error : null"
                      aria-describedby="service-validation"
                      :formatter="nameFormatter">
        </b-form-input>
        <b-form-invalid-feedback id="service-validation">
          Название сервиса должно состоять только из латинских букв и нижних подчёркиваний
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Описание сервиса">
        <b-form-input v-model="$v.serviceData.description.$model" required="required"
                      :readonly="!isAdmin"
                      :state="$v.serviceData.description.$dirty ? !$v.serviceData.description.$error : null">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Роли">
        <b-card>
          <b-form-checkbox-group :options="allRoles" stacked disabled v-model="allRoles"/>
        </b-card>
      </b-form-group>
      <b-button v-if="isAdmin" variant="primary" class="w-100 mt-3" type="submit" :disabled="submitting">
        Сохранить
      </b-button>
      <b-button class="w-100 mt-4" @click="$router.back()">
        Назад
      </b-button>
    </b-form>
  </b-container>
</template>
<script>
import rolesApi from '@/modules/roles-service';
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
  mounted() {
    if (this.$route.params.id != null) {
      this.getService(this.$route.params.id)
          .then(() => {
            document.title = `Сервис ${this.serviceData.name}`;
          })
          .catch(() => {
            document.title = "Сервис";
          });
    } else {
      this.creating = true;
    }
    this.fetchAllRoles();

  },
  data() {
    return {
      creating: false,
      submitting: false,
      allRoles: [],
      serviceData: {
        name: null,
        description: null,
        roles: []
      },
    };
  },
  validations() {
    return {
      serviceData: {
        name: {
          required,
          pattern: (value) => /^[a-zA-Z_]+$/.test(value)
        },
        description: {}
      }
    }
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    nameFormatter(value) {
      return value.toUpperCase();
    },
    onSubmit() {
      this.$v.serviceData.$touch();
      if (this.submitting || this.$v.serviceData.$anyError) {
        return;
      }
      this.submitting = true;

      this.handleResponse(this.creating ?
          rolesApi.createService(this.serviceData) :
          rolesApi.updateService(this.serviceData));
    },
    handleResponse(axiosCall) {
      axiosCall
          .then(() => {
            this.$router.push("/services");
          })
          .catch(e => {
            if (e.isAxiosError) {
              this.$bvToast.toast(e.response?.status === 403 ?
                  'Недостаточно прав на создание' :
                  e.response?.status === 409 ?
                      'Сервис с таким названием уже существует' :
                      'Возникла неизвестная ошибка при сохранении', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    getService(id) {
      return rolesApi.getService(id)
          .then(resp => {
            this.serviceData = resp.data;
          });
    },
    fetchAllRoles() {
      rolesApi.getAllRoles()
          .then(response => {
            this.allRoles = response.data;
          });
    }
  }
}
</script>
