<template>
  <b-container class="pt-2">
    <b-button class="mb-5" size="sm" variant="outline-primary" v-bind:to="`/users/${$route.params.id}/orders`">
      <b-icon-arrow-left/>
      К списку заказов
    </b-button>
    <item-info :item-get-function="getOrder" :fields="fields">
      <template #orderDate="{orderDate}">
        <b-form-input readonly :value="new Date(orderDate).toLocaleString()"/>
      </template>
      <template #deliveryDate="{deliveryDate}">
        <b-form-input readonly :value="convertDeliveryDate(deliveryDate)"/>
      </template>
      <template #deliveryType="{deliveryType}">
        <b-form-input readonly :value="convertDeliveryType(deliveryType)"/>
      </template>
      <template #deliveryAddress="{deliveryAddress}">
        <b-form-input readonly :value="convertDeliveryAddress(deliveryAddress)"/>
      </template>
      <template #paymentType="{paymentType}">
        <b-form-input readonly :value="convertPaymentType(paymentType)"/>
      </template>
      <template #products="{products}">
        <b-form-group>
          <b-list-group>
            <b-list-group-item class="border-0 p-0 mb-2" v-for="product in products" :key="product.id">
              <product-details :product=product></product-details>
            </b-list-group-item>
          </b-list-group>
        </b-form-group>
      </template>
    </item-info>
  </b-container>
</template>

<script>
import orderService from "@/modules/orders-service";
import ProductDetails from "@/views/ProductDetails.vue";

export default {
  components: {
    'product-details': ProductDetails,
  },
  name: "user-order",
  data() {
    return {
      fields: [
        {key: "id", label: "ID"},
        {key: "totalCost", label: "Сумма заказа"},
        {key: "orderDate", label: "Дата заказа"},
        {key: "deliveryDate", label: "Дата доставки"},
        {key: "deliveryType", label: "Тип доставки"},
        {key: "deliveryAddress", label: "Адрес доставки"},
        {key: "paymentType", label: "Тип оплаты"},
        {key: "products", label: "Заказанные товары"}
      ],
      productsVisible: false
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  methods: {
    async getOrder() {
      return (await orderService.getOrder(this.$route.params.orderId)).data;
    },
    convertDeliveryType(deliveryType) {
      switch (deliveryType) {
        case "BY_ADDRESS":
          return "По адресу";
        case "PICKUP":
          return "Самовывоз";
        default:
          return "";
      }
    },
    convertDeliveryDate(deliveryDate) {
      return deliveryDate == null
          ? 'Самовывоз'
          : new Date(deliveryDate).toLocaleDateString();
    },
    convertDeliveryAddress(deliveryAddress) {
      return deliveryAddress == null
          ? "Самовывоз"
          : `Улица ${deliveryAddress.street}, дом ${deliveryAddress.building}, квартира ${deliveryAddress.flat}`;
    },
    convertPaymentType(paymentType) {
      switch (paymentType) {
        case "ONLINE":
          return "Онлайн";
        case "CASH":
          return "Наличными";
        default:
          return "";
      }
    }
  }
}
</script>
<style scoped>
textarea {
  background-color: white !important;
}
</style>
