<template>
  <b-form @submit.prevent>
    <h4>Пожалуйста, смените пароль</h4>
    <b-form-group class="mt-4">
      <b-form-group label="Пароль">
        <b-form-input v-model="$v.newPassword.$model" required="required"
                      type="password"
                      :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null">
        </b-form-input>
      </b-form-group>
      <b-form-group class="mb-2" label="Повторите пароль">
        <b-form-input v-model="$v.verifyNewPassword.$model" required="required"
                      type="password"
                      :state="$v.verifyNewPassword.$dirty ? !$v.verifyNewPassword.$error : null">
        </b-form-input>
      </b-form-group>
      <div class="validation" v-if="!$v.newPassword.pattern">Пароль должен быть не менее 8 символов длиной и содержать хотя бы одну маленькую, большую буквы и цифру</div>
      <div class="validation" v-if="!$v.newPassword.doNotMatchOldPassword">Новый и старый пароли не должны совпадать</div>
      <div class="validation" v-if="!$v.verifyNewPassword.sameAsPassword">Пароли не совпадают</div>
      <b-button variant="primary" @click="changePassword" type="submit" class="mt-2 mx-auto form-control">
        Сменить пароль
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  props: {
    oldPassword: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      newPassword: null,
      verifyNewPassword: null
    }
  },
  validations() {
    return {
      newPassword: {
        pattern: (value) => new RegExp("^(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\w{8,}$").test(value),
        doNotMatchOldPassword: (value) => this.newPassword !== this.verifyNewPassword || value !== this.oldPassword
      },
      verifyNewPassword: {
        sameAsPassword: (value) => value === this.newPassword
      }
    }
  },
  methods: {
    changePassword() {
      this.$v.newPassword.$touch();
      this.$v.verifyNewPassword.$touch();
      if (this.$v.newPassword.$anyError || this.$v.verifyNewPassword.$anyError) {
        return;
      }
      this.$emit('changed', this.newPassword);
    }
  },
}
</script>
<style scoped>
.validation {
  color: #D8000C;
  font-size: 85%;
  margin-bottom: 1%;
}
</style>