import {WAITING_LIST_AXIOS} from "@/modules/backend-config";

export default {
    getWaitingList(userId, pageSize, pageNumber, sortBy, sortDirection) {
        return WAITING_LIST_AXIOS.get('/sortedAll', {
            params: {
                userId,
                pageSize,
                pageNumber,
                sortBy,
                sortDirection
            }
        });
    }
}