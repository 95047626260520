import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login';
import Service from '@/views/service/Service'
import ServiceList from '@/views/service/ServiceList';
import store from "@/store";
import UserList from "@/views/user/UserList";
import User from "@/views/user/User";
import UserProfileRead from "@/views/user/user-subpages/UserProfileRead.vue";
import UserProfileEdit from "@/views/user/user-subpages/UserProfileEdit.vue";
import UserOrderList from "@/views/user/user-subpages/UserOrderList";
import UserOrder from "@/views/user/user-subpages/UserOrder";
import UserFavoriteList from "@/views/user/user-subpages/UserFavoriteList";
import UserWaitingList from "@/views/user/user-subpages/UserWaitingList";
import UserDeliveryAddressList from "@/views/user/user-subpages/UserDeliveryAddressList";
import UserDeliveryAddress from "@/views/user/user-subpages/UserDeliveryAddress";
import UserShopCartList from "@/views/user/user-subpages/UserShopCartList";
import UserShopCartProductDetail from "@/views/user/user-subpages/UserShopCartProductDetail";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/login', component: Login, meta: {title: 'Вход'}},
        {path: '/services', component: ServiceList, meta: {title: 'Список сервисов'}},
        {path: '/services/create', component: Service, meta: {title: 'Создание сервиса'}},
        {path: '/services/:id', component: Service, meta: {title: 'Просмотр сервиса'}},
        {path: '/users', component: UserList, meta: {title: 'Список пользователей'}},
        {path: '/users/create', component: UserProfileEdit, meta: {title: 'Создание пользователя'}},
        {
            path: '/users/:id', component: User, meta: {title: 'Просмотр пользователя'},
            children: [
                {
                    path: "profile",
                    component: UserProfileRead,
                    meta: {
                        title: 'Профиль пользователя'
                    }
                },
                {
                    path: "profile/edit",
                    component: UserProfileEdit,
                    meta: {
                        title: 'Редактировать профиль пользователя'
                    }
                },
                {
                    path: "",
                    redirect: "profile"
                },
                {
                    path: "orders",
                    component: UserOrderList,
                    meta: {
                        title: 'Заказы пользователя'
                    },
                },
                {
                    path: "orders/:orderId",
                    component: UserOrder,
                    meta: {
                        title: 'Заказ пользователя'
                    }
                },
                {
                    path: "favorites",
                    component: UserFavoriteList,
                    meta: {
                        title: 'Избранные пользователя'
                    }
                },
                {
                    path: "waiting-list",
                    component: UserWaitingList,
                    meta: {
                        title: 'Список ожидания пользователя'
                    }
                },
                {
                    path: "delivery-address",
                    component: UserDeliveryAddressList,
                    meta: {
                        title: 'Адреса доставки пользователя'
                    }
                },
                {
                    path: "delivery-address/:addressId",
                    component: UserDeliveryAddress,
                    meta: {
                        title: 'Адрес доставки пользователя'
                    }
                },
                {
                    path: "shop-cart",
                    component: UserShopCartList,
                    meta: {
                        title: 'Корзина пользователя'
                    }
                },
                {
                    path: "shop-cart/:product_id",
                    component: UserShopCartProductDetail,
                    meta: {
                        title: 'Информация о продукте'
                    }
                }
            ]
        },
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    if (!store.getters.isAuthorized && '/login' !== to.path) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === to.path) {
        return next('/users');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/users' !== to.path) {
        return next('/users');
    }
    if (!store.getters.isAdmin && ['/services/create', '/users/create'].includes(to.path)) {
        return next(from.path);
    }
    if ('/' === to.path) {
        return next('/users');
    }
    return next();
});

export default router;
