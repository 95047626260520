import {SHOP_CART_AXIOS} from "@/modules/backend-config";

export default {
    getShopCart(userId, pageSize, pageNumber, sortBy, sortDirection) {
        return SHOP_CART_AXIOS.get('/sortedAll', {
            params: {
                userId,
                pageSize,
                pageNumber,
                sortBy,
                sortDirection
            }
        });
    },
    getFullInfoProductById(userId, productId){
        return SHOP_CART_AXIOS.get(`/admin/${productId}`, {
            params: {
                userId
            }
        })
    }
}
