<template>
  <b-container class="pt-2">
    <b-form class="mb-3" @submit.stop.prevent="onSubmit">
      <b-form-group label="Логин">
        <b-form-input v-model="$v.userData.login.$model" readonly
                      :state="$v.userData.login.$dirty ? !$v.userData.login.$error : null">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Email">
        <b-form-input v-model="$v.userData.email.$model" readonly></b-form-input>
      </b-form-group>
      <b-form-group label="Номер телефона">
        <b-form-input v-model="$v.userData.phone.$model" readonly></b-form-input>
      </b-form-group>
      <b-form-group label="Фамилия">
        <b-form-input v-model="$v.userData.lastName.$model" readonly></b-form-input>
      </b-form-group>
      <b-form-group label="Имя">
        <b-form-input v-model="$v.userData.firstName.$model" readonly></b-form-input>
      </b-form-group>
      <b-form-group label="Отчество">
        <b-form-input v-model="$v.userData.patronymic.$model" readonly></b-form-input>
      </b-form-group>
      <b-form-group label="Дата рождения">
        <b-form-datepicker placeholder="Выберите дату" v-model="$v.userData.dateOfBirth.$model"
                           readonly></b-form-datepicker>
      </b-form-group>
      <b-form-group v-if="!creating" label="Email подвтерждён">
        <b-form-input readonly :value="userData.emailConfirm ? 'Да' : 'Нет'"></b-form-input>
      </b-form-group>
      <b-form-group v-if="!creating" label="Сгенерированный пароль сменён">
        <b-form-input readonly :value="userData.passwordActivated ? 'Да' : 'Нет'"></b-form-input>
      </b-form-group>
      <b-button class="w-100 mt-4" @click="$router.back()">
        Назад
      </b-button>
    </b-form>
  </b-container>
</template>
<script>
import userApi from '@/modules/users-service';
import serviceApi from '@/modules/roles-service';
import {required} from "vuelidate/lib/validators";
import AwesomeMask from 'awesome-mask';

export default {
  directives: {
    'mask': AwesomeMask
  },
  async mounted() {
    if (this.$route.params.id != null) {
      await this.fetchUser(this.$route.params.id);
    } else {
      this.creating = true;
    }
    this.getAllRoles();
    this.setUserRoles();
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      creating: false,
      submitting: false,
      currentService: null,
      allServices: [],
      allRoles: [],
      fields: [
        {
          key: 'service', label: 'Сервис'
        }
      ],
      verifyPassword: null,
      userData: {
        login: null,
        phone: null,
        firstName: null,
        lastName: null,
        patronymic: null,
        dateOfBirth: null,
        email: null,
        password: null,
        emailConfirm: null,
        passwordActivated: null,
        services: []
      },
    };
  },
  validations() {
    return {
      userData: {
        login: {
          required
        },
        email: {
          pattern: (value) => new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
        },
        password: {
          required: (value) => this.creating ? value !== null && value.length > 0 : true
        },
        phone: {
          pattern: (value) => value == null || value.length === 0 || new RegExp(/^\+\d-\d{3}-\d{3}-\d{2}-\d{2}$/).test(value)
        },
        firstName: {},
        lastName: {},
        patronymic: {},
        dateOfBirth: {},
        services: {},
      },
      currentService: {},
      allServices: {},
      allRoles: {},
      verifyPassword: {
        required: (value) => this.creating ? value !== null && value.length > 0 : true,
        sameAsPassword: (value) => value == null || value.length === 0 || value === this.userData.password
      }
    }
  },
  methods: {
    onSubmit() {
      this.$v.userData.$touch();
      if (this.submitting || this.$v.userData.$anyError || this.$v.verifyPassword.$anyError) {
        return;
      }
      this.submitting = true;
      this.handleResponse(this.creating ?
          userApi.createUser(this.userData) :
          userApi.updateUser(this.userData));
    },
    handleResponse(axiosCall) {
      axiosCall
          .then(() => {
            this.$router.push('/users');
          })
          .catch(e => {
            if (e.isAxiosError) {
              this.$bvToast.toast(e.response?.status === 403 ?
                  'Недостаточно прав на создание' :
                  e.response?.status === 409 ?
                      'Пользователь с таким логином уже существует' :
                      'Возникла неизвестная ошибка при сохранении', {
                title: 'Ошибка',
                autoHideDelay: 5000,
                appendToast: true
              });
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    fetchUser(id) {
      return userApi.getUser(id)
          .then(resp => {
            this.userData = resp.data;
          });
    },
    setUserRoles() {
      serviceApi.getServices()
          .then(response => {
            const receivedServices = response.data;
            const rootService = receivedServices.find(service => service.name === 'ROOT');
            const sortedServices = receivedServices.filter(service => service !== rootService);
            sortedServices.push(rootService);
            this.allServices = sortedServices;
            this.userData.services = sortedServices
                .map(service => {
                  const foundService = this.userData.services
                      .find(userService => service.id === userService.id);
                  return {
                    serviceName: service.name,
                    roles: foundService == null || foundService.roles == null ? [] : foundService.roles
                  }
                });
          });
    },
    getAllRoles() {
      serviceApi.getAllRoles()
          .then(response => {
            this.fields.push(...response.data);
          });
    }
  }
}
</script>
<style scoped>

</style>
