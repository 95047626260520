<template>
  <b-container class="pt-2">
    <div v-if="isRolesEmpty">
      <h1 class="my-4">У вас нет доступа к данному сервису</h1>
    </div>
    <div v-else>
      <div v-if="isAdmin" class="d-flex">
        <router-link to="/users/create" class="btn btn-primary mb-2">Создать пользователя</router-link>
      </div>
      <b-button v-b-toggle.filters-collapse variant="primary" class="mb-2">Фильтры</b-button>
      <b-collapse id="filters-collapse" role="tabpanel" class="p-2">
        <div v-if="filters[field.key] != null" v-for="field in fields" :key="field.key">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" :for="field.key">{{ field.label }}</label>
            <div class="col-sm-10">
              <b-input class="form-control" :id="field.key" v-model="filters[field.key]" :placeholder="field.label"/>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label" :for="userTypeSelect">Тип пользователя</label>
          <div class="col-sm-10">
            <b-select id="userTypeSelect" v-model="filters['userType']" :options="userTypeOptions"/>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-10">
            <b-button variant="primary" @click="$refs.user_table.refresh()">Поиск</b-button>
          </div>
        </div>
      </b-collapse>
      <b-table
          hover
          responsive
          :items="getUserDataTable"
          :fields="fields"
          :current-page="currentPage"
          ref="user_table"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="table-bordered table-hover user-table"
          show-empty>
        <template v-slot:empty="scope">
          <h6 class="text-center">Пользователи отсутствуют</h6>
        </template>
        <template v-slot:cell(id)="row">
          {{ row.item.id }}
        </template>
        <template v-slot:cell(fio)="row">
          {{ buildFio(row.item) }}
        </template>
        <template v-slot:cell(phone)="row">
          {{ row.item.phone }}
        </template>
        <template v-slot:cell(login)="row">
          {{ row.item.login }}
        </template>
        <template v-slot:cell(email)="row">
          {{ row.item.email }}
        </template>
        <template v-slot:cell(interaction)="row">
          <b-button class="ml-1" variant="primary"
                    title="Просмотр"
                    @click="prepareDetailPage(row.item.id)">
            <b-icon-eye/>
          </b-button>
          <b-button class="ml-1" variant="primary"
                    v-if="isAdmin"
                    title="Редактирование"
                    @click="prepareEditDetailPage(row.item.id)">
            <b-icon-pencil/>
          </b-button>
          <b-button class="ml-1 mt-1 mt-md-0" variant="primary"
                    v-if="isAdmin"
                    title="Удалить"
                    @click="deleteUser(row.item.id)">
            <b-icon-trash/>
          </b-button>
        </template>
      </b-table>
      <div class="d-flex mb-2">
        <div class="col-md-2 pl-0">
          <b-form-select
              v-model="perPage"
              class="text-light border-secondary bg-secondary">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </b-form-select>
        </div>
        <span class="align-self-center d-none d-lg-block mb-2">записей на страницу</span>
        <b-pagination
            v-model="currentPage"
            class="my-0 ml-auto mb-2"
            :total-rows="totalCount"
            :per-page="perPage"
            aria-controls="template-table"
            @input="getUserDataTable">
        </b-pagination>
      </div>
    </div>
  </b-container>
</template>
<script>
import userApi from '@/modules/users-service';
import {mapGetters} from "vuex";

export default {
  name: 'user-list',
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      totalCount: 0,
      fields: [
        {
          key: 'id', label: 'ID', sortable: true
        },
        {
          key: 'fio', label: 'ФИО', sortable: false
        },
        {
          key: 'phone', label: 'Номер телефона', sortable: true
        },
        {
          key: 'login', label: 'Логин', sortable: true
        },
        {
          key: 'email', label: 'Email', sortable: true
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      filters: {
        phone: '',
        login: '',
        email: '',
        userType: 'ALL'
      },
      userTypeOptions: [
        {value: 'ALL', text: 'Все'},
        {value: 'REAL', text: 'Реальный'},
        {value: 'IMPORTED', text: 'Для отзыва'},
      ]
    };
  },
  mounted() {
    document.title = this.$route.meta.title;
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "isRolesEmpty"
    ])
  },
  methods: {
    getUserDataTable() {
      if (!this.sortBy){
        this.sortBy = 'id'
      }

      return userApi.getUsers(this.currentPage, this.perPage, this.sortBy,
          this.sortDesc ? "Descending" : "Ascending", this.filters)
          .then(resp => {
            this.totalCount = resp.data.totalCount;
            return resp.data.users;
          });
    },
    deleteUser(id) {
      //запуск модального окна
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить пользователя?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              userApi.deleteUser(id)
                  .then(() => {
                    return this.$refs.user_table.refresh();
                  })
                  .catch(error => {
                    this.$bvToast.toast(error.response?.status === 403 ?
                        'Недостаточно прав на удаление' :
                        error.response?.status === 404 ?
                            'Пользователь не найден' :
                            'Возникла неизвестная ошибка при удалении', {
                      title: 'Ошибка',
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                  });
            }
          });
    },
    prepareDetailPage(id) {
      this.$router.push(`/users/${id}`);
    },
    prepareEditDetailPage(id) {
      this.$router.push(`/users/${id}/profile/edit`);
    },
    buildFio(item){
      return `${item.lastName ?? ''} ${item.firstName ?? ''} ${item.patronymic ?? ''}`
    }
  }
}
</script>
<style>
@media only screen and (max-width: 768px) {
  .column-direction {
    flex-direction: column;
  }
}
</style>
